.stepNumber {
  background: var(--chakra-colors-brand-white);
  color: var(--chakra-colors-brand-black);
  width: 45px;
  height: 45px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 15px;
  margin-top: 10px;
  background: #0000006e;
  color: #ffffff;
  border: 1px solid #ffffff26;
  border: 1px solid #ffffff61;
}

.step__title {
  text-align: left;
  margin-bottom: 10px !important;
}

.step__description {
  text-align: left;
  color: inherit;
  margin: 0 !important;
  margin-top: 10px !important;
}

@media only screen and (min-width: 769px) {
  .stepNumber {
    margin-bottom: 30px;
    width: 60px;
    height: 60px;
    font-size: 25px;
    margin-top: 0;
  }

  .step__title {
    /* text-align: center; */
    margin-bottom: 24px !important;
  }

  .step__description {
    margin-top: 24px !important;
  }
}
